* {
  image-rendering: pixelated;
}

#date {
  background: url("date_background.a632fba1.png");
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 115px;
  height: 25px;
  display: flex;
  position: absolute;
  top: 680px;
}

#date .date-date {
  text-transform: lowercase;
  color: #e2881d;
  text-shadow: 0 0 8px #e2881d00;
  font-family: edit-undo;
  font-size: 19px;
}

#time {
  background: url("time_background.6b6bcefe.png");
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 25px;
  display: flex;
  position: absolute;
  top: 710px;
}

#time .date-time {
  text-transform: lowercase;
  color: #e2881d;
  text-shadow: 0 0 8px #e2881d00;
  font-family: edit-undo;
  font-size: 19px;
}
/*# sourceMappingURL=index.ed10cfb1.css.map */
